import AutoComplete from "react-google-autocomplete";
import {Select} from "antd";
import moment from "moment"; 


const {Option} = Select;
const options = [1, 2, 3, 4];

const ReptileCreateForm = (props) => {
    const {values, setValues, handleChange, handleImageChange, handleSubmit, location, setLocation} = props

    const {title, content, price} = values;
    return (
        <form onSubmit={handleSubmit}>
        <div className="form-group">
            <label className="btn btn-outline-secondary btn-block m-2 text-left">
                Image
                <input 
                type="file" 
                name="image" 
                onChange={handleImageChange} 
                accept="image/*" 
                hidden
                />
            </label>
            <input 
            type="text" 
            name="title" 
            onChange={handleChange} 
            placeholder="Title" 
            className="form-control m-2" 
            value={title}
            />

            <textarea 
            name="content" 
            onChange={handleChange} 
            placeholder="Content" 
            className="form-control m-2" 
            value={content}
            />

            <AutoComplete 
            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            className="form-control ml-2 mr-2"
            placeholder="Enter City Location"
            defaultValue={location}
            language="en"
            onPlaceSelected={(place) => { setLocation(place.formatted_address) } }
            style={{height:"50px"}}
            />

            <input 
            type="number" 
            name="price" 
            onChange={handleChange} 
            placeholder="Price" 
            className="form-control m-2" 
            value={price}
            />
            
        </div>

        <button className="btn btn-outline-primary m-2">Save</button>
    </form>
    );
};

export default ReptileCreateForm;