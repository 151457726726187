import axios from 'axios';

export const createConnectAccount = async (token) => 
await axios.post(
    `${process.env.REACT_APP_PROD_API}/create-connect-account`,
{}, 
{
    headers: {
        Authorization: `Bearer ${token}`,
    },
}
);
export const getAccountStatus = async (token) =>
  axios.post(
    `${process.env.REACT_APP_PROD_API}/get-account-status`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  export const getAccountBalance = async (token) =>
  axios.post(
    `${process.env.REACT_APP_PROD_API}/get-account-balance`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const currencyFormatter = data => {
return (data.amount / 100).toLocaleString(data.currency, {
    style: 'currency',
    currency: data.currency,
});
};

export const payoutSetting = async (token) => await axios.post(`${process.env.REACT_APP_PROD_API}/payout-setting`, {},{
  headers: {
    Authorization: `Bearer ${token}`,
  },
})

export const getReptileSessionId = async (token, reptileId) => await axios.post(`${process.env.REACT_APP_PROD_API}/reptile-stripe-session-id`, {reptileId,},
{ 
  headers: {
    Authorization: `Bearer ${token}`,
},
})

export const stripeReptileSuccessRequest = async (token, reptileId) => await axios.post(`${process.env.REACT_APP_PROD_API}/reptile-stripe-success`, {reptileId}, {
  headers: {
    Authorization: `Bearer ${token}`,
  },
})

export const getSessionId = async (token, enclosureId) => await axios.post(`${process.env.REACT_APP_PROD_API}/stripe-session-id`, {enclosureId,},
{ 
  headers: {
    Authorization: `Bearer ${token}`,
},
})

export const stripeSuccessRequest = async (token, enclosureId) => await axios.post(`${process.env.REACT_APP_PROD_API}/stripe-success`, {enclosureId}, {
  headers: {
    Authorization: `Bearer ${token}`,
  },
})