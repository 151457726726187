import React, { useState } from 'react';
import { Button } from '../ButtonElements';
import {SideBtnWrap, SidebarRoute} from '../Sidebar/SidebarElements'
import { useSelector } from 'react-redux';

import Video from '../../videos/video.mp4';
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight
} from './HeroElements';

function HeroSection() {
  const [hover, setHover] = useState(false);
  const {auth} = useSelector((state) => ({...state}));

  const onHover = () => {
    setHover(!hover);
  };
  return (
    <HeroContainer id='home'>
      <HeroBg>
        <VideoBg playsInline autoPlay loop muted src={Video} type='video/mp4' />
      </HeroBg>
      <HeroContent>
        <HeroH1>Reptile Marketplace</HeroH1>
        <HeroP>
          Purchase Exclusive Reptiles From Breeders
        </HeroP>
         <HeroBtnWrapper>
         {auth == null && (<SideBtnWrap>
          <SidebarRoute to='/signup'>Sign Up</SidebarRoute>
        </SideBtnWrap>)}
        {auth !== null && (<SideBtnWrap>
          <SidebarRoute to='/dashboard'>Dashboard</SidebarRoute>
        </SideBtnWrap>)}
          {/* <Button
            to='signup'
            smooth={true}
            duration={500}
            spy={true}
            exact='true'
            offset={-80}
            primary='true'
            dark='true'
            onMouseEnter={onHover}
            onMouseLeave={onHover}
          >
            Get Started {hover ? <ArrowForward /> : <ArrowRight />}
          </Button> */}
        </HeroBtnWrapper> 
        
      </HeroContent>
    </HeroContainer>
  );
}

export default HeroSection;
