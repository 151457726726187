import React from 'react';
import './App.css';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import Home from './pages';
import SigninPage from './pages/signin';
import SignupPage from './pages/signup';
import Dashboard from './user/Dashboard';
import DashboardSeller from './user/DashboardSeller';
import StripeCallback from './stripe/StripeCallback';
import NewReptile from './reptiles/NewReptile';
import EditReptile from './reptiles/EditReptile';
import ViewReptile from './reptiles/ViewReptile';
import StripeSuccess from './stripe/StripeSuccess';
import StripeCancel from './stripe/StripeCancel';
import PrivacyPolicy from './special/PrivacyPolicy';


function App() {
  return (
    <Router>
      <ToastContainer />
      <Switch>
        <Route path='/' component={Home} exact />
        <Route path='/signin' component={SigninPage} exact />
        <Route path='/signup' component={SignupPage} exact />
        <Route path='/privacypolicy' component={PrivacyPolicy} exact />
        <PrivateRoute path='/dashboard' component={Dashboard} exact />
        <PrivateRoute path='/dashboard/seller' component={DashboardSeller} exact />
        <PrivateRoute path='/stripe/callback' component={StripeCallback} exact />
        <PrivateRoute path='/reptile/new' component={NewReptile} exact />
        <PrivateRoute path='/reptile/edit/:reptileId' component={EditReptile} exact />
        <Route path='/reptile/:reptileId' component={ViewReptile} exact />
        <PrivateRoute path='/stripe/success/:reptileId' component={StripeSuccess} exact />
        <PrivateRoute path='/stripe/cancel' component={StripeCancel} exact />
      </Switch>
    </Router>
  );
}

export default App;
