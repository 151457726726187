import {currencyFormatter} from "../../actions/stripe";
import { useHistory} from 'react-router';
import { useState } from "react";
import OrderModal from "../modals/OrderModal";
import { Link } from "react-router-dom";
import {EditOutlined, DeleteOutlined} from "@ant-design/icons"

const ReptilePurchaseCard = ({
    reptile,
    session,
    orderedBy

}) => {
const [showModal, setShowModel] = useState(false);
const history = useHistory();
return (
    <>
        <div className="card mb-3">
            <div className="row no-gutters">
                <div className="col-md-4">
                    {reptile.image && reptile.image.contentType ? (
                        <img src={`${process.env.REACT_APP_PROD_API}/reptile/image/${reptile._id}`} alt="Default Reptile Image" className="card-image img img-fluid" />
                    ) :
                    (
                        <img src="https://via.placeholder.com/900x500.png?text=FastDrop+Booking" alt="Default Reptile Image" className="card-image img img-fluid" />
                    )
                    }
                </div>
                <div className="col-m-8">
                    <div className="card-body">
                        <h3 className="card-title">{reptile.title} <span className="float-right text-primary">
                            {currencyFormatter({
                                amount: reptile.price * 100,
                                currency:"usd"
                            })}
                            </span>
                        </h3>
                        <p className="alert alert-info">{reptile.location}</p>
                        <p className="card-text">{`${reptile.content.substring(0,200)}`}</p>
                        
                        {/* {showModal && (<OrderModal session={session} orderedBy={orderedBy} reptile={reptile} showModal={showModal} setShowModel={setShowModel}/>)}
                        <div className="d-flex justify-content-between h4">
                             <button onClick={()=> setShowModel(!showModal)} className="btn btn-primary">Show Payment Info</button>
                            
                        </div> */}
                    </div>

                </div>
            </div>
        </div>
    </>
)};

export default ReptilePurchaseCard; 