import DashboardNav from "../components/DashboardNav";
import ConnectNav from '../components/ConnectNav';
import { Link } from "react-router-dom";
// import { userEnclosureBookings } from "../actions/enclosure";
import { userReptilePurchases } from "../actions/reptile";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
// import BookingCard from "../components/cards/BookingCard";
import ReptilePurchaseCard from "../components/cards/ReptilePurchaseCard";
import TopNav from "../components/TopNav";

const Dashboard = () => {
    const {
        auth: {token},
    } = useSelector((state) => ({...state}));

    // const [booking, setBooking] = useState([]);
    const [purchase, setPurchase] = useState([]);
    useEffect(()=> {
        // loadUserBookings()
        loadUserPurchases()
    }, [])

    // const loadUserBookings = async () => {
    //     const res = await userEnclosureBookings(token)
    //     console.log(res)
    //     setBooking(res.data)
    // }
    const loadUserPurchases = async () => {
        const res = await userReptilePurchases(token)
        console.log(res)
        setPurchase(res.data)
    }
    return (
        <>
        <TopNav />
            <div className="container-fluid bg-secondary p-5">  
                <ConnectNav />
            </div>

            <div className="container-fluid.p-4">
                <DashboardNav />
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-10">
                        <h2>Your Purchases</h2>
                    </div>
                    <div className="col-md-2">
                        <Link to="/" className="btn btn-primary">Browse Reptiles</Link>
                    </div>
                </div>
            </div>

            <div className="row">
                {purchase.map((p) =>(
                    <ReptilePurchaseCard key={p._id} reptile={p.reptile} session={p.session} orderedby={p.orderedBy}/>
                ))}
            </div>
        </>
    );
};

export default Dashboard; 