import {useState} from 'react'
import React from 'react';
import { Link, useHistory } from 'react-router';
import { toast } from 'react-toastify';
import {signup} from '../../actions/auth';
import {
  Container,
  FormWrap,
  Icon,
  FormContent,
  Form,
  FormH1,
  FormLabel,
  FormInput,
  FormButton
} from './SignupElements';

const SignUp = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const res = await signup({
        name,
        email,
        password
      });
      console.log('Registered User: ', res)
      toast.success('Sign up successfully. Please login.');
      history.push('/signin');
    } catch (err) {
      console.log(err);
      if(err.response.status === 400) toast.error(err.response.data);
    }
  };

  return (
    <>
      <Container>
        <FormWrap>
          <Icon to='/'>FastDrop</Icon>
          <FormContent>
            <Form onSubmit={handleSubmit}>
              <FormH1>Sign Up for FastDrop</FormH1>
              <FormLabel htmlFor='for'>Name</FormLabel>
              <FormInput 
              type='name' 
              placeholder="Enter Name" 
              value={name} 
              onChange={(e)=> setName(e.target.value)} 
              required />
              <FormLabel htmlFor='for'>Email</FormLabel>
              <FormInput 
              type='email'  
              placeholder="Enter Email" 
              value={email} 
              onChange={(e)=> setEmail(e.target.value)}  
              required />
              <FormLabel htmlFor='for'>Password</FormLabel>
              <FormInput 
              type='password'  
              placeholder="Enter Password" 
              value={password} 
              onChange={(e)=> setPassword(e.target.value)} 
              required />
              <FormButton type='submit'>Continue</FormButton>
            </Form>
          </FormContent>
        </FormWrap>
      </Container>
    </>
  );
};

export default SignUp;