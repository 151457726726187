import React from 'react';
import {useState} from 'react'
import { toast } from 'react-toastify';
import {signin} from '../../actions/auth';
import {useDispatch} from 'react-redux';
import { useHistory } from 'react-router';
import {
  Container,
  FormWrap,
  Icon,
  FormContent,
  Form,
  FormH1,
  FormLabel,
  FormInput,
  FormButton,
  Text
} from './SigninElements';

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const res = await signin({
        email,
        password
      });
      toast.success('Successful Sign In');
      //save user and token to local storage
      window.localStorage.setItem('auth', JSON.stringify(res.data));
      //save user and token to redux
      dispatch({
        type: "LOGGED_IN_USER",
        payload: res.data
      });
      
      history.push('/dashboard');

    } catch (err) {
      console.log(err);
      if(err.response.status === 400) toast(err.response.data);
    }
  };

  return (
    <>
      <Container>
        <FormWrap>
          <Icon to='/'>FastDrop</Icon>
          <FormContent>
            <Form onSubmit={handleSubmit}>
              <FormH1>Sign Into FastDrop</FormH1>
              <FormLabel htmlFor='for'>Email</FormLabel>
              <FormInput 
              type='email'  
              placeholder="Enter Email" 
              value={email} 
              onChange={(e)=> setEmail(e.target.value)}  
              required />
              <FormLabel htmlFor='for'>Password</FormLabel>
              <FormInput 
              type='password'  
              placeholder="Enter Password" 
              value={password} 
              onChange={(e)=> setPassword(e.target.value)} 
              required />
              <FormButton type='submit'>Continue</FormButton>
              <Text>Forgot Password</Text>
            </Form>
          </FormContent>
        </FormWrap>
      </Container>
    </>
  );
};
export default SignIn;
