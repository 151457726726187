import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SidebarRoute,
  SideBtnWrap
} from './SidebarElements';

const Sidebar = ({ isOpen, toggle }) => {
  const {auth} = useSelector((state)=>({...state}));
  const history = useHistory();
  const dispatch = useDispatch();
  const signout = () => {
    dispatch({
      type: "LOGOUT",
      payload: null,
    });
    window.localStorage.removeItem('auth');
    history.push('/')
  }

  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink
            to='about'
            onClick={toggle}
            smooth={true}
            duration={500}
            spy={true}
            exact='true'
            offset={-80}
          >
            About
          </SidebarLink>
          <SidebarLink
            to='discover'
            onClick={toggle}
            smooth={true}
            duration={500}
            spy={true}
            exact='true'
            offset={-80}
          >
            Discover
          </SidebarLink>
          <SidebarLink
            to='services'
            onClick={toggle}
            smooth={true}
            duration={500}
            spy={true}
            exact='true'
            offset={-80}
          >
            Services
          </SidebarLink>
          {/* <SidebarLink
            to='signup'
            onClick={toggle}
            smooth={true}
            duration={500}
            spy={true}
            exact='true'
            offset={-80}
          >
            Sign Up
          </SidebarLink> */}
        </SidebarMenu>      
        {auth ==null && (<SideBtnWrap>
          <SidebarRoute to='/signup'>Sign Up</SidebarRoute>
        </SideBtnWrap>)}
      
        {auth == null &&(<SideBtnWrap>
          <SidebarRoute to='/signin'>Sign In</SidebarRoute>
        </SideBtnWrap>)}
        {auth !== null &&(<SideBtnWrap>
          <SidebarRoute to='/dashboard'>Dashboard</SidebarRoute>
        </SideBtnWrap>)}
        {auth !== null &&(<SideBtnWrap>
          <SidebarRoute onClick={signout}>Sign Out</SidebarRoute>
        </SideBtnWrap>)}
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
