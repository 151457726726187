import { BookFilled } from "@ant-design/icons";
import axios from "axios";

export const createReptile = async (token, data) => {
await axios.post(`${process.env.REACT_APP_PROD_API}/create-reptile`, data,
{
    headers: {
        Authorization: `Bearer ${token}`,
    },
})
};

export const allReptiles = async () => await axios.get(`${process.env.REACT_APP_PROD_API}/reptiles`);

// export const diffDays = (from, to) => {
//     const day = 24 * 60 * 60 * 1000;
//     const start = new Date(from);
//     const end = new Date(to);
//     const difference = Math.round(Math.abs((start - end) /day));
//     return difference;
// }

export const sellerReptiles = async (token) => await axios.get(`${process.env.REACT_APP_PROD_API}/seller-reptiles`, {
    headers: {
        Authorization: `Bearer ${token}`,
    },
})



export const read = async (reptileId) =>
  await axios.get(`${process.env.REACT_APP_PROD_API}/reptile/${reptileId}`);


export const updateReptile = async (token, data, reptileId) => {
await axios.put(`${process.env.REACT_APP_PROD_API}/update-reptile/${reptileId}`, data,
{
    headers: {
        Authorization: `Bearer ${token}`,
    },
})
};

export const userReptilePurchases = async (token) => await axios.get(`${process.env.REACT_APP_PROD_API}/user-reptile-purchases`, {
    headers: {
        Authorization: `Bearer ${token}`,
    }
});

export const isAlreadyPurchased = async (token, reptileId) => await axios.get(`${process.env.REACT_APP_PROD_API}/is-already-purchased/${reptileId}`, {
    headers: {
        Authorization: `Bearer ${token}`,
    }
});