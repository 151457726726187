import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { DatePicker, Select } from "antd";
// import { read, updateEnclosure } from "../actions/enclosure";
import {read, updateReptile} from "../actions/reptile";
import { useSelector } from "react-redux";
// import EnclosureEditForm from "../components/forms/EnclosureEditForm";
import ReptileEditForm from "../components/forms/ReptileEditForm";
import TopNav from "../components/TopNav";

const { Option } = Select;

const EditReptile = ({match}) => {
    //redux
    const {auth} = useSelector((state)=>({...state}));
    const {token} = auth;

    //state
    const [values, setValues] = useState({
        title: '',
        content: '',
        location:'',
        price: ''
    }); 
    const [image, setImage] = useState("");
    const [preview, setPreview] = useState("https://via.placeholder.com/100x100.png?text=PREVIEW");

    // destructuring variables from state 
    const {title, content, price, from, to, tank, location} = values;

    useEffect(()=> {
        loadSellerReptile()
    }, []);

    const loadSellerReptile = async () =>{
        let res = await read(match.params.reptileId)
        setValues({...values, ...res.data});
        setPreview(`${process.env.REACT_APP_PROD_API}/reptile/image/${res.data._id}`);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let reptileData = new FormData()
        //use a map for this next part 
        reptileData.append('title', title);
        reptileData.append('content', content);
        reptileData.append('location', location);
        reptileData.append('price', price);
        image && reptileData.append("image",image);

        try {
            let res = await updateReptile(token, reptileData, match.params.reptileId)
            toast.success("Reptile Has Updated Information");
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        } catch (err) {
            console.log(err);
            toast.error(err.response.data);
        }
    };

    const handleImageChange = (e) => {
        setPreview(URL.createObjectURL(e.target.files[0]));
        setImage(e.target.files[0]);
    }

    const handleChange = (e) => {
        setValues({...values, [e.target.name]: e.target.value});
    };
    
    return (
        <>
        <TopNav />
            <div className="container-fluid bg-secondary p-5 text-center">
                <h2> Edit Reptile</h2>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-10">
                        <br />
                        <ReptileEditForm 
                        values={values}
                        setValues={setValues}
                        handleChange={handleChange}
                        handleImageChange={handleImageChange}
                        handleSubmit={handleSubmit}
                    />
                    </div>
                    <div className="col-md-2">
                        <img src={preview} alt="preview_image" className="img img-fluid m-2" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditReptile;