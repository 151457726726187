import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
// import { allEnclosures } from '../actions/enclosure';
// import SmallCard from '../components/cards/SmallCard';
import {allReptiles} from '../actions/reptile'
import ReptileSmallCard from '../components/cards/ReptileSmallCard';
import {
  homeObjOne,
  homeObjTwo,
  homeObjThree
} from '../components/InfoSection/Data';
import Services from '../components/Services';

function Home() {
  const [isOpen, setIsOpen] = useState(false);
  // const [enclosures, setEnclosures] = useState([]);
  const [reptiles, setReptiles] = useState([]);

  useEffect(()=> {
    let abortController = new AbortController();
    // loadAllEnclosures();
    loadAllReptiles();
    return () => {
      abortController.abort();
    }
  },[])

  // const loadAllEnclosures = async () => {
  //   let res = await allEnclosures();
  //   setEnclosures(res.data)
  // };
  const loadAllReptiles = async () => {
    let res = await allReptiles();
    setReptiles(res.data);
  }

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      <div className="container-fluid">
        <br />
        {reptiles.map((r)=> <ReptileSmallCard key={r.userId} r={r}/>)}
      </div>
      <Services />
      <Footer />
    </>
  );
}

export default Home;
