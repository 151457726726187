import axios from 'axios';

export const signup = async (user) => await axios.post(`${process.env.REACT_APP_PROD_API}/signup`,user);

export const signin = async (user) => await axios.post(`${process.env.REACT_APP_PROD_API}/signin`,user);

// update user in local storage
export const updateUserInLocalStorage = (user, next) => {
    if(window.localStorage.getItem('auth')){
        let auth = JSON.parse(localStorage.getItem("auth"));
        auth.user = user;
        localStorage.setItem("auth", JSON.stringify(auth));
        next();
    }
};

