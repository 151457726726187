import React, {useState, useEffect} from 'react'; 
import { useSelector } from 'react-redux';
import { stripeReptileSuccessRequest } from '../actions/stripe';
import { useHistory} from 'react-router';
import {LoadingOutlined} from '@ant-design/icons';

const StripeSuccess = ({match}) => {
    const history = useHistory()
    const {
        auth: {token},
    } = useSelector((state) => ({...state}));

    useEffect(() => {
        // console.log('send this reptileid to backend to create order', match.params.enclosureId)
        stripeReptileSuccessRequest(token, match.params.reptileId).then(res=> {
            if(res.data.success) {
                // console.log('stripe success response', res.data);
                history.push('/dashboard');
            } else {
                history.push('/stripe/cancel');
            }
        });
    }, [match.params.reptileId])
    
    return (
        <div className='container'>
            <div className="d-flex justify-content-center p-5">
                <LoadingOutlined className="display-1 text-danger p-5" />
            </div>
        </div>
    )
}

export default StripeSuccess; 