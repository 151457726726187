import { useState } from "react";
import { toast } from "react-toastify";
import AutoComplete from "react-google-autocomplete";
import {DatePicker, Select} from "antd";
import moment from "moment"; 
// import { createEnclosure } from "../actions/enclosure";
import {createReptile} from "../actions/reptile";
import {useSelector} from "react-redux";
// import EnclosureCreateForm from "../components/forms/EnclosureCreateForm";
import ReptileCreateForm from "../components/forms/ReptileCreateForm";
import TopNav from "../components/TopNav";

const {Option} = Select;
const options = [1, 2, 3, 4];

const NewReptile = () => {
    //redux
    const {auth} = useSelector((state)=>({...state}));
    const {token} = auth;
    //state
    const [values, setValues] = useState({
        title: '',
        content: '',
        image: '',
        price: '',
    });
    const [preview, setPreview] = useState("https://via.placeholder.com/100x100.png?text=PREVIEW");
    // destructuring variables from state
    const [location, setLocation] = useState('')
    const {title, content, image, price} = values;

    const handleSubmit = async (e) =>{
        e.preventDefault();

        let reptileData = new FormData()
        //use a map for this next part 
        reptileData.append('title', title)
        reptileData.append('content', content)
        reptileData.append('location', location)
        reptileData.append('price', price)
        image && reptileData.append("image",image)

        console.log([...reptileData]);
        

        try {

            let res = await createReptile(token, reptileData)
            toast.success("New Reptile Is Posted");
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        } catch (err) {
            console.log(err);
            toast.error(err.response.data);
        }
    }

    const handleImageChange = (e) => {
        setPreview(URL.createObjectURL(e.target.files[0]));
        setValues({...values, image: e.target.files[0]});
    }

    const handleChange = (e) => {
        setValues({...values, [e.target.name]: e.target.value});
    };


    

    return (
        <>
        <TopNav />
        <div className="container-fluid bg-secondary p-5 text-center">
            <h2>Add Reptile</h2>
        </div>

        <div className="container-fluid">
            <div className="row">
                <div className="col-md-10">
                    <br/>
                    <ReptileCreateForm 
                        values={values}
                        setValues={setValues}
                        handleChange={handleChange}
                        handleImageChange={handleImageChange}
                        handleSubmit={handleSubmit}
                        location={location}
                        setLocation={setLocation}
                    />
                </div>
                <div className="col-md-2">
                    <img src={preview} alt="preview_image" className="img img-fluid m-2" />
                </div>
            </div>
        </div>
        </>
    );
};

export default NewReptile;