import DashboardNav from "../components/DashboardNav";
import ConnectNav from "../components/ConnectNav";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {BugOutlined} from '@ant-design/icons';
import { useState, useEffect } from "react";
import {toast} from "react-toastify";
import { createConnectAccount } from "../actions/stripe";
// import { sellerEnclosures, deleteEnclosure } from "../actions/enclosure";
import { sellerReptiles } from "../actions/reptile";
// import SmallCard from "../components/cards/SmallCard";
import ReptileSmallCard from "../components/cards/ReptileSmallCard";
import TopNav from "../components/TopNav";

const DashboardSeller = () => {
    const {auth} = useSelector((state)=>({...state}));
    const [reptiles, setReptiles] = useState([]);
    // const [enclosures, setEnclosures] = useState([])
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let abortController = new AbortController();
        // loadSellerEnclosures()
        loadSellerReptiles()
        return () => {
            abortController.abort();
          }
    }, [])

    // const loadSellerEnclosures = async () => {
    //     let {data} = await sellerEnclosures(auth.token)
    //     setEnclosures(data);
    // };

    const loadSellerReptiles = async () => {
        let {data} = await sellerReptiles(auth.token)
        setReptiles(data);
    };

    const handleClick = async () =>{
        setLoading(true)
        try {
            let res = await createConnectAccount(auth.token);
            console.log(res);
            window.location.href = res.data;
        } catch(err){
            console.log(err)
            toast.error('Stripe connect failed, Try again.')
            setLoading(false)
        }
    };

    // const handleEnclosureDelete = async (enclosureId) =>{
    //     if(!window.confirm('Are you sure you want to delete this enclosure?')) return;
    //     deleteEnclosure(auth.token, enclosureId).then(res => {
    //         toast.success('Enclosure Deleted');
    //         loadSellerEnclosures();
    //     })
    // }
    

    const connected = () => (
        <div className="container-fluid">
        <div className="row">
            <div className="col-md-10">
                <h2>Your Reptiles</h2>
            </div>
            <div className="col-md-2">
                <Link to="/reptile/new" className="btn btn-primary">+ Add New</Link>
            </div>
        </div>
        <div className="row">
            {reptiles.map((r) => <ReptileSmallCard key={r._id} r={r} showViewMoreButton={false} owner={true} />)}
            {/* handleEnclosureDelete={handleEnclosureDelete} */}
            {/* {JSON.stringify(enclosures)} */}
        </div>
    </div>
    );
    const notConnected = () => (
        <div className="container-fluid">
        <div className="row">
            <div className="col-md-6 offset-md-3 text-center">
                <div className="p-5 pointer">
                    <BugOutlined className="h1"/>
                    <h2>Setup Payouts to Post Reptiles</h2>
                    <p className="lead">FastDrop partners with Stripe to transfer earnings to your bank account.</p>
                </div>
                <button 
                    disabled={loading}
                    onClick={handleClick} 
                    className=" btn btn-primary mb-3"
                > 
                {loading ? "Processing...":"Setup Payouts"}
                </button>
                <p className="text-muted">
                    <small>
                        You'll be redirected to Stripe to complete the onboarding process. 
                    </small>
                </p>
            </div>
        </div>
    </div>
    );
    return (
        <>
        <TopNav />
            <div className="container-fluid bg-secondary p-5">
                <ConnectNav />
            </div>

            <div className="container-fluid.p-4">
                <DashboardNav />
            </div>

            {auth && 
            auth.user && 
            auth.user.stripe_seller && 
            auth.user.stripe_seller.charges_enabled && {}
            ? connected() 
            : notConnected()}
        </>
    );
};

export default DashboardSeller; 