import React, {useState, useEffect} from "react";
import {read, isAlreadyPurchased } from "../actions/reptile";
import { getReptileSessionId } from "../actions/stripe";
import {useSelector} from 'react-redux';
import { useHistory} from 'react-router';
import {loadStripe} from '@stripe/stripe-js';
import TopNav from "../components/TopNav";

const ViewReptile = ({match}) => {
    // const [enclosure, setEnclosure] = useState({});
    const [reptile, setReptile] = useState({});
    const [image, setImage] = useState("");
    const [loading, setLoading] = useState(false);
    const [alreadyPurchased, setAlreadyPurchased] = useState(false);
    const history = useHistory()
    const {auth} = useSelector((state) => ({...state}));
    useEffect (()=>{
        loadSellerReptile();
    }, []);

    useEffect(() =>{
        if(auth && auth.token){
            isAlreadyPurchased(auth.token, match.params.reptileId)
            .then(res =>{
                if(res.data.ok) setAlreadyPurchased(true);
            });
        }
    }, []);

    const loadSellerReptile = async () =>{
        let res = await read(match.params.reptileId)
        console.log(res.data);
        setReptile(res.data);
        // console.log(res.data);
        setImage(`${process.env.REACT_APP_PROD_API}/reptile/image/${res.data._id}`);
    };

    const handleClick =  async (e) => {
        e.preventDefault();

        if (!auth || !auth.token) {
            history.push("/signin");
            return;
        }

        setLoading(true)
        if (!auth) history.push('/signin')
        // console.log(auth.token, match.params.enclosureId)
        let res = await getReptileSessionId(auth.token, match.params.reptileId);
        // console.log('get sessionId response', res.data.sessionId);
        const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY)
        stripe.redirectToCheckout({
            sessionId: res.data.sessionId,
        })
        .then((result) => console.log(result));
    };

    return (<> 
        <TopNav />
        <div className="container-fluid bg-secondary p-5 text-center">
                <h2> {reptile.title}</h2>
            </div>
            <div className="containter-fluid">
                <div className="row">
                    <div className="col-md-6">
                        <br />
                        <img src={image} alt={reptile.title} className="img img-fluid m-2" />
                    </div>

                    <div className="col-md-6">
                        <br />
                        <b>{reptile.content}</b>
                        <p className="alert alert-info mt-3">${reptile.price}</p>

                        <i> Posted by {reptile.postedBy && reptile.postedBy.name}</i>
                        <br />
                        <button 
                        onClick={handleClick}
                        className="btn btn-block btn-lg btn-primary mt-3"
                        disabled={loading || alreadyPurchased}
                        > 
                        {loading ? "Loading..." : alreadyPurchased ? "Already Purchased" :auth && auth.token ? 'Purchase Now': 'Login to Purchase'}</button>
                    </div>
                </div>
            </div>
    </>
    )}

export default ViewReptile; 