import {currencyFormatter} from "../../actions/stripe";
import { diffDays } from "../../actions/enclosure";
import { useHistory} from 'react-router';
import { Link } from "react-router-dom";
import {EditOutlined, DeleteOutlined} from "@ant-design/icons"

const ReptileSmallCard = ({r, handleReptileDelete =(f) => f,
owner = false,
showViewMoreButton = true,
}) => {
const history = useHistory();
return (
    <>
        <div className="card mb-3">
            <div className="row no-gutters">
                <div className="col-md-4">
                    {r.image && r.image.contentType ? (
                        <img src={`${process.env.REACT_APP_PROD_API}/reptile/image/${r._id}`} alt="Default Reptile Image" className="card-image img img-fluid" />
                    ) :
                    (
                        <img src="https://via.placeholder.com/900x500.png?text=FastDrop+Booking" alt="Default Reptile Image" className="card-image img img-fluid" />
                    )
                    }
                </div>
                <div className="col-m-8">
                    <div className="card-body">
                        <h3 className="card-title">{r.title} <span className="float-right text-primary">
                            {currencyFormatter({
                                amount: r.price * 100,
                                currency:"usd"
                            })}
                            </span>
                        </h3>
                        <p className="alert alert-info">{r.location}</p>
                        <p className="card-text">{`${r.content.substring(0,200)}`}</p>
                        
                        <div className="d-flex justify-content-between h4">
                            {showViewMoreButton &&( <button onClick={()=> history.push(`/reptile/${r._id}`)} className="btn btn-primary">Show More</button>)}
                            {
                                owner && (<>
                                    <Link to={`/reptile/edit/${r._id}`}>
                                <       EditOutlined className="text-warning"/>
                                    </Link>
                                </>)
                            }
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </>
)};

export default ReptileSmallCard; 