import React from 'react';
import Icon1 from '../../images/directory.svg';
import Icon2 from '../../images/doctor.svg';
import Icon3 from '../../images/blog.svg';
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP
} from './ServicesElements';

const Services = () => {
  return (
    <ServicesContainer id='services'>
      <ServicesH1>FAQs</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Icon1} />
          <ServicesH2>Breeder's Reptiles</ServicesH2>
          <ServicesP>
            All the Reptiles for sale are from reputable breeders! 
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon2} />
          <ServicesH2>Pick & Shipping</ServicesH2>
          <ServicesP>
            We have pickup and shipping options available! 
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon3} />
          <ServicesH2>Digital First Approach</ServicesH2>
          <ServicesP>
            We are dedicated to a digital first approach to reptile purchases! 
          </ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
